html,
body,
#root {
    height: 100%;
    background-color: var(--site-BG);
}

:root {
    --black: #000000;
    --hover: #696969;
    --title-border-btm: #BFBFBF;
    --container-width: 1070px;
    --site-BG: #f2f2f2;
    --tablet-break: 1024px;
    --large-mobile: 600px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}