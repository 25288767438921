body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Lato', sans-serif;
}

.container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: 60px auto 0;
  width: 100%;
  opacity: 1;
  max-width: var(--container-width);
  height: calc(100vh - 259px);
  border: 1px solid var(--black);
  /* animation: 500ms 1s fadeIn forwards;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease; */
}

.content {
  width: 100%;
  font-size: 16px;
  color: var(--black);
  text-align: left;
  overflow-y: auto;
  padding: 30px;
  border: 10px;
  line-height: 1.4;
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: calc(100vw - 60px);
    height: calc(100vh - 206px);
  }
}

@media screen and (max-width: 600px) {
  .container {
    height: calc(100vh - 175px);
  }

  .content {
    padding: 15px;
  }
}