@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Lato', sans-serif;
}

.container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: 60px auto 0;
  width: 100%;
  opacity: 1;
  max-width: var(--container-width);
  height: calc(100vh - 259px);
  border: 1px solid var(--black);
  /* animation: 500ms 1s fadeIn forwards;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease; */
}

.content {
  width: 100%;
  font-size: 16px;
  color: var(--black);
  text-align: left;
  overflow-y: auto;
  padding: 30px;
  border: 10px;
  line-height: 1.4;
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: calc(100vw - 60px);
    height: calc(100vh - 206px);
  }
}

@media screen and (max-width: 600px) {
  .container {
    height: calc(100vh - 175px);
  }

  .content {
    padding: 15px;
  }
}
/* font-family: 'Lato', sans-serif; */
html,
body,
#root {
    height: 100%;
    background-color: #f2f2f2;
    background-color: var(--site-BG);
}

:root {
    --black: #000000;
    --hover: #696969;
    --title-border-btm: #BFBFBF;
    --container-width: 1070px;
    --site-BG: #f2f2f2;
    --tablet-break: 1024px;
    --large-mobile: 600px;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.about-content .email {
    color: inherit;
}
.main-para span {
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin: 5px auto;
    line-height: normal;
}

.main-sm {
    font-size: 30px;
    font-weight: 300;
}

.main-lg {
    font-size: 50px;
    font-weight: 400;
}
/* 
span:nth-child(1) {
    opacity: 0;
    animation: 500ms 2s fadeIn forwards;
}

span:nth-child(2) {
    opacity: 0;
    animation: 500ms 2.5s fadeIn forwards;
}

span:nth-child(3) {
    opacity: 0;
    animation: 500ms 3s fadeIn forwards;
}

span:nth-child(4) {
    opacity: 0;
    animation: 500ms 3.5s fadeIn forwards;
}

span:nth-child(5) {
    opacity: 0;
    animation: 500ms 4s fadeIn forwards;
}

span:nth-child(6) {
    opacity: 0;
    animation: 500ms 4.5s fadeIn forwards;
}

span:nth-child(7) {
    opacity: 0;
    animation: 500ms 5s fadeIn forwards;
} */

@media screen and (max-width: 1024px) {
    .main-sm {
        font-size: 20px;
    }

    .main-lg {
        font-size: 36px;
    }
}

@media screen and (max-width: 600px) {

    .main-para span {
        letter-spacing: 3px;
    }

    .main-sm {
        font-size: 14px;
    }

    .main-lg {
        font-size: 15px;
    }
}
.nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-bottom: 1px solid #000000;
    width: 100%;
    max-width: var(--container-width);
    height: auto;
    margin: auto;
    font-family: 'Lato', sans-serif;
    /* animation: 500ms linear fadeIn; */
}

.nav-title {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 550px;
    padding: 40px 0;
    -webkit-animation: 500ms linear fadeIn;
            animation: 500ms linear fadeIn;
}

.nav-title div {
    font-size: calc(30px + 2vmin);
    text-transform: uppercase;
    letter-spacing: 8px;
    font-weight: 300;
    color: inherit;
    text-decoration: none;
    transition: all 500ms ease;
    cursor: pointer;
}

.nav-title div:hover {
    letter-spacing: 9px;
    transition: all 500ms ease;
}

.nav-anchors {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    color: #B2B2B2;
    text-transform: uppercase;
    opacity: 0;
    -webkit-animation: 500ms fadeIn forwards;
            animation: 500ms fadeIn forwards;
}

.links {
    text-decoration: none;
    color: inherit;
}

.nav-anchors div {
    padding: 5px 25px;
    letter-spacing: 3px;
    text-decoration: none;
    color: #B2B2B2;
    transition: all 500ms ease;
    cursor: pointer;
}

.nav-anchors div:hover {
    padding: 5px 25px;
    letter-spacing: 3px;
    color: var(--hover);
    text-decoration: none;
    transition: all 500ms ease;
}

.active div {
    padding: 5px 25px;
    letter-spacing: 3px;
    text-decoration: none;
    color: var(--black);
    transition: all 500ms ease;
}

.nav-anchors div::after {
    content: "";
    position: relative;
    display: flex;
    width: 0;
    height: 1px;
    border: none;
    left: 50%;
    opacity: 0;
    border-bottom: 1px solid;
    transition: all 500ms ease;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
}

.nav-anchors div:hover::after,
.active-line div::after {
    border-bottom: 1px solid;
    width: 97%;
    left: 49%;
    opacity: 1;
}

@media screen and (max-width: 1024px) {
    .nav-title {
        max-width: 100%;
        padding: 30px 0;
    }

    .nav-title div {
        font-size: calc(30px + 2vmin);
    }

    .nav-title div:hover {
        letter-spacing: 8px;
    }

    .nav-anchors {
        display: none;
    }

    .active div {
        padding: 0;
        letter-spacing: 2px;
        text-decoration: none;
        color: var(--black);
        transition: all 500ms ease;
    }
}

@media screen and (max-width: 600px) {

    .nav-title div {
        font-size: calc(16px + 1vmin);
        letter-spacing: 5px;
    }

    .nav-title div:hover {
        letter-spacing: 5px;
    }

    .nav-anchors {
        display: none;
    }
}

#mobile-nav {
    display: none;
}

.mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    background-color: var(--site-BG);
    text-align: left;
    border: none;
    border-right: 1px solid var(--black);
    padding: 100px 50px 100px 2rem;
    position: absolute;
    top: 0;
    left: 0;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    z-index: 1;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.mobile-links div {
    padding: 5px 0;
    letter-spacing: 3px;
    text-decoration: none;
    text-transform: uppercase;
    color: #B2B2B2;
    transition: all 500ms ease;
    cursor: pointer;
}

/* .mobile-links div:hover {
    padding: 5px 0;
    letter-spacing: 3px;
    color: var(--hover);
    text-decoration: none;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
} */

.active-mobile div {
    padding: 5px 0;
    letter-spacing: 3px;
    text-decoration: none;
    color: var(--black);
    transition: all 500ms ease;
}

.mobile-links div::after {
    content: "";
    position: relative;
    display: flex;
    width: 0;
    height: 1px;
    border: none;
    left: 50%;
    opacity: 0;
    border-bottom: 1px solid;
    transition: all 500ms ease;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
}

.mobile-links div:hover::after,
.active-line-mobile div::after {
    border-bottom: 1px solid;
    width: 97%;
    left: 49%;
    opacity: 1;
}


@media screen and (max-width: 1024px) {
    #mobile-nav {
        display: flex;
    }
}

@media screen and (max-width: 600px) {}
.resume {
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.resume-left {
    width: 40%;
    margin: 0 15px 10px 0;
}

.resume-right {
    width: 60%;
    margin: 0 0 10px 15px;
}

.sub-content {
    border: 1px solid var(--black);
    padding: 30px;
}

.sub-content ul {
    list-style-type: square;
    margin: auto;
    padding: 0 0 0 20px;
}

.resume-left .sub-content:last-child {
    margin: 0;
    padding: 30px 30px 32px;
}

.resume-left .sub-content {
    border: 1px solid var(--black);
    padding: 30px;
    margin: 0 auto 30px;
}

.brk {
    border: none;
    border-bottom: 1px solid var(--title-border-btm);
    margin: 15px 0;
    width: 50%;
}

.title {
    border: none;
    border-bottom: 1px solid var(--title-border-btm);
    margin: 0 auto 15px;
    font-size: 18px;
}

@media screen and (max-width: 1024px) {

    .resume {
        flex-wrap: wrap;
    }

    .resume-left,
    .resume-right {
        width: 100%;
        margin: 0 auto;
    }

    .resume-left .sub-content:last-child {
        margin: 0 auto 30px;
        padding: 15px;
    }
}

@media screen and (max-width: 600px) {

    .resume-left,
    .resume-right {
        width: 100%;
        margin: 0 auto;
    }

    .resume-left .sub-content {
        margin: 0 auto 15px;
        padding: 15px;
    }

    .sub-content {
        padding: 15px;
    }

    .resume-left .sub-content:last-child {
        margin: 0 auto 15px;
    }
}
